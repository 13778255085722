import React, { useState } from 'react';
import './JobApplicationForm.css';
import axios from 'axios';

const JobApplicationForm = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [resume, setResume] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object and append form data
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('resume', resume);

    try {
      // Send POST request to server
      await axios.post('http://localhost:8081/apply', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // Show success message, clear form, and close popup
      alert('Application submitted successfully');
      resetForm();
      onClose();
    } catch (error) {
      // Log and show error message if request fails
      console.error('Error submitting application', error);
      alert('Failed to submit application');
    }
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setResume(null);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Apply for Job</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" style={{color:"black"}}>Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{color:"black"}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" style={{color:"black"}}>Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{color:"black"}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" style={{color:"black"}}>Phone No:</label>
            <input
              type="number"
              id="phone"
              name="phone"
              className="form-control"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{color:"black"}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="resume" style={{color:"black"}}>Resume:</label>
            <input
              type="file"
              id="resume"
              name="resume"
              className="form-control"
              required
              onChange={(e) => setResume(e.target.files[0])}
              style={{color:"black"}}
            />
          </div>
          <div>
            <button type="submit" className="btn btn-primary">Submit</button>
            <button type="button" style={{ marginLeft: "10px" }} className="btn btn-secondary" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobApplicationForm;
