import React,{useState} from 'react'
import './Careers.css'
import { FaUserGraduate } from "react-icons/fa6";
import { RiFontSize } from 'react-icons/ri';
import JobApplicationForm from './JobApplicationForm';
import { TiLocationArrowOutline } from "react-icons/ti";
import Topheader from '../Topheader';

const Careers = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);

    const openForm = () => {
        setIsFormOpen(true);
    };

    const closeForm = () => {
        setIsFormOpen(false);
    };
    return (
        <>
        <Topheader/>
        <div>
            {/* <section className="page-header">
                <div className="page-header__bg_10"></div>


                <div className="container">

                </div>
            </section> */}

<section className="page-header">
                <div className="page-header__bg_10"></div>
                {/* <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div> */}
                
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Careers</h2>
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a property="item" typeof="WebPage" title="Go to Ontech." href="/" className="home">
                                        <span property="name">Home</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">Careers</span>
                                   
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="section-header text-center">
                            <h2 className="fw-bold fs-1">
                                Job
                                <span className="b-class-secondary"></span> Applications
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
                        <div className="col">
                            <div className='card_carrer_container'>
                                   <div className='card_sub_container'>
                                       <h6 style={{color:"white"}}>Get your dream jobs</h6>
                                       <h4 style={{color:"#FFB237"}}>Find your perfect job match</h4>
                                       <p style={{color:"white"}}>Connect with our nearest office or submit an online inquiry for business opportunities.</p>
                                       <button onClick={openForm} style={{marginTop:"14px"}} className="button_22">Apply</button>
                                   </div>
                            </div>
                        </div>
                        <div className="col">
                        <div className="service-card">
                                <div className="icon-wrapper">
                                    <FaUserGraduate />
                                </div>
                                <h3>Cnc Programmer</h3>
                                <p style={{textAlign:"justify"}}><span style={{fontWeight:"bold",color:"black"}}>Description</span> : Develop, test, and implement CNC machine programs to produce precision parts, ensuring quality, efficiency, and adherence to specifications.</p>
                                <div style={{display:"flex",justifyContent:"center",flexDirection:"column",marginLeft:"-30px"}}>
                                    <ul style={{textAlign:"start"}}>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Qualification</span>:B.E/B.Tech</li>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Job Mode</span>:Full Time</li>
                                    </ul>
                                    <ul style={{textAlign:"start"}}>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Salary</span>:20k-30k</li>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Skills</span>: 2D Drawings, Programmimg</li>
                                    </ul>
                                </div>
                                <button className="button_22" onClick={openForm}>Apply</button>
                            </div>
                        </div>
                        <div className="col">
                        <div className="service-card">
                                <div className="icon-wrapper">
                                    <FaUserGraduate />
                                </div>
                                <h3>Design Engineering</h3>
                                <p style={{textAlign:"justify"}}><span style={{fontWeight:"bold",color:"black"}}>Description</span> : Create, test, and implement software solutions for design and engineering projects and innovation and compliance with technical specifications.</p>
                                <div style={{display:"flex",justifyContent:"center",flexDirection:"column",marginLeft:"-30px"}}>
                                    <ul style={{textAlign:"start"}}>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Qualification</span>:B.E/B.Tech</li>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Job Mode</span>:Full Time</li>
                                    </ul>
                                    <ul style={{textAlign:"start"}}>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Salary</span>:30k-40k</li>
                                        <li><TiLocationArrowOutline/><span style={{fontWeight:"bold",color:"black"}}>Skills</span>: Catia,Auto Cad</li>
                                    </ul>
                                </div>
                                <button className="button_22" onClick={openForm}>Apply</button>
                            </div>
                        </div>
                        {/* Additional service cards can be added here */}
                    </div>
                </div>
                <JobApplicationForm isOpen={isFormOpen} onClose={closeForm} />
            </section>


        </div>
        </>
    )
}

export default Careers
