import React, { useState } from "react";
import './Enquiry.css'
import { MdCancel } from "react-icons/md";

const Slide = () => {
    const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);

    const handleQuickOptionClick = () => {
        setDemoOptionOpen(!isDemoOptionOpen);
    };

    const [status, setStatus] = useState("Submit");

    const [info, setInfo] = useState({
        name: "",
        mobilenumber: "",
        email: "",
        selectedOption: "",
        subOption: "",
    });

    const handleChange = (event) => {
        setInfo({
            ...info,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(info);
        setStatus("Sending...");

        let response = await fetch(`/api/inquiry`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                //'Content-Type': 'application/json',
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(info),
            //body: JSON.stringify(details),
        });
        //console.log(details);
        console.log(info);
        setStatus("Submit");
        setInfo({
            name: "",
            mobilenumber: "",
            email: "",
            selectedOption: "",
            subOption: "",
        });
        let result = await response.json();
        //alert(result.status);
    };
    const getOptions = () => {
        switch (info.selectedOption) {
            case "Mechanical":
                return [
                    "AUTOCAD",
                    "CATIA",
                    "NXCAD",
                    "CREO",
                    "AUTO INVENTOR",
                    "ANSYS",
                    "REVIT MEP",
                    "CNC",
                    "ART CAM",
                    "NX CAM",
                ];
            case "Civil":
                return [
                    "AUTOCAD",
                    "REVIT ARCH",
                    "SKETCH UP",
                    "REVIT STRU",
                    'V-RAYS',
                    'LUMION',
                    'STAAD PRO',
                    'ETAB',
                    'CIVIL 3D',
                    'PRIMAVERA',
                ];
            case "Electrical":
                return [
                    "AUTOCAD",
                    "AUTO ELECTRICal",
                    "EPLAN",
                    "MATLAB",
                    "PLC",
                    "SCADA",

                ];
            case "Computer":
                return [
                    " C/C++",
                    "MS-OFFICE",
                    "TALLY",
                    "PHOTOSHOP",
                    "DCA",
                    "PGDCAA",
                    "ADVANCE PGDCAA"
                ];

            default:
                return [];
        }
    };
    const nameRegex = /^[a-zA-Z\s]+$/;
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        if (nameRegex.test(inputValue) || inputValue === "") {
            info.name(inputValue);
        }
    };

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        if (phoneRegex.test(inputValue) || inputValue === "") {
            info.phone(inputValue);
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        if (emailRegex.test(inputValue) || inputValue === "") {
            info.email(inputValue);
        }
    };

    return (
        <>
            <div
                className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}
            >
                <div className="aeroland__toolbar">
                    <div
                        className="inner"
                        style={{ background: "orange", textAlign: "right" }}
                    >
                        <button
                            className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
                            style={{
                                color: "White",
                                transform: `rotate(180deg) ${isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
                                    }`,
                                fontSize: "18px", // Adjust the font size as needed
                                border: "none",
                                // Remove the border
                                background: "transparent",
                                fontWeight: "bold",
                                outline: "none" /* Remove default focus outline */,
                                border: "none",
                                // Optional: Adjust the negative margin to fine-tune the vertical position
                                marginLeft: "-38px",
                                lineHeight: "8.15",
                                color: "black",
                                textAlign: "center",
                                marginTop:"-20px"
                            }}
                            onClick={handleQuickOptionClick}
                        >
                            ReachUs
                        </button>
                    </div>
                </div>

                <div className="demo-option-wrapper">
                    <button
                        className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
                        onClick={handleQuickOptionClick}
                        style={{ backgroundColor: "#FFB237" }}
                    >
                        <MdCancel  style={{fontSize:"40px"}} />
                    </button>

                    <div className="business-solution-form-wrap mr-auto ml-auto">
                        <div className="contact-title text-center section-space--mb_40">
                            <b>
                                <h3 className="mb-10">Contact Us</h3>
                            </b>
                        </div>

                        <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
                            <input type="hidden" name="valid" value="false" />
                            <div class="contact-form__two">
                                <br />

                                <div className="contact-inner" style={{ width: "260px" }}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder="Name *"
                                        required
                                        id="name"
                                        pattern="[a-zA-Z\s]+"
                                        title="Please enter only alphabets"
                                        value={info.name}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="contact-inner" style={{ width: "260px" }}>
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="Email id *"
                                        required
                                        id="email"
                                        pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                                        title="Please enter a valid email address"
                                        value={info.email}
                                        onChange={handleChange}
                                    />
                                </div>
                               

                                <div className="contact-inner" style={{ width: "260px" }}>
                                    <input
                                        name="mobilenumber"
                                        type="text"
                                        placeholder="Contact No *"
                                        required
                                        id="mobilenumber"
                                        pattern="\d{10}"
                                        title="Please enter a 10-digit phone number"
                                        value={info.mobilenumber}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-3">
                                    <select
                                        className="form-control p-3 border-0"
                                        value={info.selectedOption || ""}
                                        name="selectedOption"
                                        required
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Category</option>
                                        <option value="Mechanical">Mechanical</option>
                                        <option value="Civil">Civil</option>
                                        <option value="Electrical">Electrical</option>
                                        <option value="Computer">Computer</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    {info.selectedOption && (
                                        <select
                                            className="form-control p-3 border-0"
                                            required
                                            name="subOption"
                                            value={info.subOption}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select {info.selectedOption}</option>
                                            {getOptions().map((subOption) => (
                                                <option key={subOption} value={subOption}>
                                                    {subOption}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>

                                <br />

                                <div
                                    class="comment-submit-btn text-center"
                                    style={{ color: "#ffc107" }}
                                >
                                    <button
                                        class="ht-btn ht-btn-md"
                                        type="submit"
                                        name="submitEnqury"
                                        id="submitEnqury"
                                        style={{ backgroundColor: "#ffc107" }}
                                    >
                                        Submit
                                    </button>
                                    {/* <!--  <p class="form-messege"></p> --> */}
                                </div>

                              
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Slide;
