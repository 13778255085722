import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FaFacebook, FaTwitter, FaPinterest, FaInstagram, FaLinkedin, FaSkype, FaEnvelope } from 'react-icons/fa';
import { FiPhoneCall } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import MobileNav1 from './Mobilenav1'
import MobileNav from './MobileNav';

function Topheader() {


    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Function to toggle mobile menu state
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };
    return (
        <div>
            <div class="page-wrapper">
               

                <header class="main-header main-header-one main-header-one--three clearfix">
                    <div class="main-header-one--three__top">
               
                        <div class="container">
                            <div class="main-header-one--three__top-inner">
                            <MobileNav1 />
                                <div class="main-header-one--three__top-left">
                                    <div class="logo-three">
                                        <div class="shape1" style={{ width: "290px" }}>
                                            <img src="/images/newlogo.jpg" alt="stts" style={{ height: "100px" }} /></div>
                                        {/* <a href="">
                            <img src="assets/images/resources/logo-3.png" alt="#" /></a> */}
                                    </div>



                                    <div class="main-header__contact-info" style={{ marginLeft: "120px" }}>
                                        <ul>
                                            <li>
                                                <div className="inner" style={{ marginLeft: "0px" }}>
                                                    <div className="icon-box" style={{ fontSize: "larger" }}>
                                                        <FaEnvelope className="icon-email " style={{ color: "black",fontSize: "larger" }} /> {/* Displaying the email icon */}
                                                    </div>
                                                    <div className="text-box">
                                                        <p>You may send an Email</p>
                                                        <h4><a href="mailto: sttstrainings@gmail.com"> sttstrainings@gmail.com</a></h4>
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="inner" style={{ marginLeft: "-40px" }}>
                                                    <div class="icon-box" style={{ fontSize: "20px" }}>
                                                        <FiPhoneCall style={{ color: "black",fontSize: "larger" }} />
                                                    </div>
                                                    <div class="text-box">
                                                        <p>Get In Touch</p>
                                                        <h4>+91 9692114127 / 9337219166</h4>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div class="main-header-one--three__top-right">
                                    <ul className="social-link">
                                        <li><a href="https://www.linkedin.com/in/shawtech-training-51b92b2b3" target='_blank'><FaLinkedin /></a></li>
                                        <li><a href="https://www.instagram.com/shawtechtrainings" target='_blank'><FaInstagram /></a></li>
                                        <li><a href="https://www.facebook.com/profile.php?id=61556382478829" target='_blank'><FaFacebook /></a></li>
                                        {/* <li><a href="#"><FaSkype /></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="main-header-one__bottom">


                        <nav class="main-menu clearfix">
                            <div class="main-menu__wrapper clearfix">
                                <div class="container">
                                    <div class="main-header-one__bottom-inner">
                                        <div class="main-header-one__bottom-left">
                                            <div class="main-menu__main-menu-box">
                                                <div className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                                                    {/* Hamburger icon using Font Awesome */}
                                                    {/* <FontAwesomeIcon icon={faBars} /> */}
                                                </div>

                                                <ul className={`main-menu__list ${isMobileMenuOpen ? 'show' : ''}`}>
                                                    <li className="dropdown underline-hover-effect">
                                                        <Link to="/" ><b style={{ color: "white" }}>Home</b></Link>
                                                    </li>
                                                    <li className="dropdown underline-hover-effect">
                                                        <Link to="/about" ><b style={{ color: "white" }} >About</b></Link>
                                                    </li>
                                                    <li className="dropdown underline-hover-effect">
                                                        <Link to="/Courses" ><b style={{ color: "white" }}>Courses</b></Link>
                                                    </li>
                                                    <li className="dropdown underline-hover-effect">
                                                        <Link to="/gallery" ><b style={{ color: "white" }}>Gallery</b></Link>
                                                    </li>
                                                    <li  className="dropdown underline-hover-effect">
                                                        <Link to="/contact" ><b style={{ color: "white" }}>Contact</b></Link>
                                                    </li>
                                                    <li  className="dropdown underline-hover-effect">
                                                        <Link to="/Careers" ><b style={{ color: "white" }}>Careers</b></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="main-header-one__bottom-right">
                                            <div className="main-header-one--three__bottom-btn">
                                                <Link to="/Contact"><b style={{ color: "black" }}>Contact us</b></Link>
                                            </div>
                                            <div className="main-header-one--three__bottom-btn">
                                                <a href='https://ocmtechnologys.com/shaw-tech/' target='_black'><b style={{ color: "black" }}>Login</b></a>
                                            </div>

                                     

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>


                    </div>
                    <MobileNav/>
                    
                </header>

               


                <div class="stricky-header stricky-header__three stricked-menu main-menu">
                    <div class="sticky-header__content">
                        <div class="main-menu__wrapper clearfix">
                            <div class="container">
                                <div class="main-header-one__bottom-inner">
                                    <div class="main-header-one__bottom-left">
                                        <div class="main-menu__main-menu-box">
                                            <a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>
                                            <ul className="main-menu__list">
                                                <li className="dropdown current">
                                                    <Link to="/" ><b style={{ color: "white" }}>Home</b></Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/About" ><b style={{ color: "white" }}>About</b></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Courses" ><b style={{ color: "white" }}>Courses</b></Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Gallery" ><b style={{ color: "white" }}>Gallery</b></Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact" ><b style={{ color: "white" }}>Contact</b></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Careers" ><b style={{ color: "white" }}>Careers</b></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="main-header-one__bottom-right">
                                        <div class="main-header-one--three__bottom-btn">
                                            <a href="/Contanct">Contact us</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    )
}

export default Topheader            