import React,{useEffect} from 'react'
import Topheader from '../Components/Topheader'
const Gallery = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // loop over all the entries
      entries.forEach((entry) => {
        console.log(entry);
        // use a conditional to check if the entry is intersection
        if (entry.isIntersecting) {
          // add show class
          entry.target.classList.add("show");
        } else {
          // remove when not intersecting
          entry.target.classList.remove("show");
        }
      });
    });
    
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));    
  })
  return (
    <>
     <Topheader/>

     <section className="page-header">
        <div className="page-header__bg_3"></div>
        {/* <div
          className="page-header__gradient"
          style={{
            backgroundImage:
              "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)",
          }}
        ></div> */}
        <div className="shape1 float-bob-x">
          <img src="./images/sttsbanner.png" alt="banner shape" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Gallery</h2>

            <ul className="thm-breadcrumb list-unstyled ml-0">
              {/* Breadcrumb NavXT 7.2.0 */}
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Ontech."
                    href="/"
                    className="home"
                  >
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">
                    Gallery
                  </span>
                  
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

  <div className="gallery " >
        <img src="./images/shaw1.jpeg" alt="" style={{height:"100px"}}/>
        <img src="./images/shaw2.jpeg" alt=""/>
        <img src="./images/shaw3.jpeg" alt=""/>
        <img src="./images/shaw4.jpeg" alt="" style={{height:"100px"}}/>
        <img src="./images/shaw5.jpeg" alt=""/>
        <img src="./images/g1.jpeg" alt=""/>
        <img src=" ./images/g2.jpeg" alt="" style={{height:"100px"}}/>
        <img src=" ./images/g3.jpeg" alt=""/>
        <img src=" ./images/g4.jpeg" alt=""/>
        <img src=" ./images/g5.jpeg" alt="" style={{height:"100px"}}/>
        <img src=" ./images/g6.jpeg" alt=""/>
        <img src="./images/g7.jpeg" alt=""/>
        <img src="./images/g8.jpeg" alt=""/>

        <img src=" ./images/g9.jpeg" alt="" style={{height:"100px"}}/>
        <img src=" ./images/g10.jpeg" alt=""/>
        <img src="./images/g11.jpeg" alt=""/>
    </div>

   
    <div className='container video_responsive' > 
       <div className='row'>
           <div className='col-12 col-md-6 col-lg-4'>
            <video width="320" height="200" controls style={{marginRight: "10px"}}>
              <source src="./images/g12.mp4" type="video/mp4"/>
            </video>
           </div>

           <div className='col-12 col-md-6 col-lg-4'>
            <video width="320" height="200" controls style={{marginRight: "10px"}}>
              <source src="./images/g13.mp4" type="video/mp4"/>
            </video>
           </div>

           <div className='col-12 col-md-6 col-lg-4'>
            <video width="320" height="200" controls style={{marginRight: "10px"}}>
              <source src="./images/g14.mp4" type="video/mp4"/>
            </video>
           </div>

           <div className='col-12 col-md-6 col-lg-3'>
            
           </div>

           <div className='col-12 col-md-6 col-lg-4 responsive_video'>
            <video width="320" height="200" controls style={{marginRight: "10px"}}>
              <source src="./images/g15.mp4" type="video/mp4"/>
            </video>
           </div>

           <div className='col-12 col-md-6 col-lg-4'>
            <video width="320" height="200" controls style={{marginRight: "10px"}}>
              <source src="./images/g16.mp4" type="video/mp4"/>
            </video>
           </div>

           <div className='col-12 col-md-6 col-lg-3'>
            
           </div>

       </div>
    </div>

   
    
    </>
  )
}

export default Gallery
