// Write your code here
import {Link} from 'react-router-dom'
import React, { useEffect } from "react";
import './AppItem.css'

const AppItem = props => {
  const {Appitem} = props
  const {appName, category, imageUrl, backinformation, link = '', appId, my} = Appitem;


  const handleCardClick = () =>{
    if(link.startsWith('http')){
      window.open(link,'_blank')
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // loop over all the entries
      entries.forEach((entry) => {
        console.log(entry);
        // use a conditional to check if the entry is intersection
        if (entry.isIntersecting) {
          // add show class
          entry.target.classList.add("show");
        } else {
          // remove when not intersecting
          entry.target.classList.remove("show");
        }
      });
    });
    
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));    
  })

  return (
    // <div className="appItemcontainer">
    //   <img src={imageUrl} alt={appName} className="appimage" />
    //   <p className="appname">{appName}</p>
    // </div>

    <>
        <div className="card_1 hidden" onClick={handleCardClick}>
    <div className="upper-part_1">
        <div className="upper-part-face_1">
        <img src={imageUrl} alt={appName} className="appimage" />
        </div>
    
        <div className="upper-part-back_1"> <Link to={`/Coursesdetails/${appId}`} style={{color:"white",fontSize:"17px"}}>{backinformation}..<br/>
        <span style={{color:"#FFB237"}}>Read More</span>
        
        
        </Link>
        
        </div>
    </div>
    <div className="lower-part_1">
        <div className="lower-part-face_1">
        {appName}
        </div>
        <a ><div className="lower-part-back_1">Register</div></a>
        
    </div>
    </div>
    </>


  )
}
export default AppItem
