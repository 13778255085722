import React from 'react'
import Topheader from '../Components/Topheader'
import AppStore from './AppStore'
import mechanicalroad from './mechanicalroad'

const Courses = () => {
    return (
        <>

            <Topheader/>
            <section className="page-header">
                <div className="page-header__bg_2"></div>
                {/* <div
                    className="page-header__gradient"
                    style={{
                        backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)',
                    }}
                ></div> */}
                <div className="shape1 float-bob-x">
                    <img
                        src="./images/sttsbanner.png"
                        alt="banner shape"
                    />
                </div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>COURSES</h2>

                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            {/* Breadcrumb NavXT 7.2.0 */}
                            <li className="home">
                                <span
                                    property="itemListElement"
                                    typeof="ListItem"
                                >
                                    <a
                                        property="item"
                                        typeof="WebPage"
                                        title="Go to Ontech."
                                        href="/"
                                        className="home"
                                    >
                                        <span property="name">Home</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span
                                    property="itemListElement"
                                    typeof="ListItem"
                                >
                                    <span
                                        property="name"
                                        className="post post-page current-item"
                                    >
                                        Courses
                                    </span>
                                    
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

          
             <AppStore/>
        </>
    )
}

export default Courses
